import { QueryClientContext } from "../../context/QueryClientContext";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLogin } from "../../api/useAuth";
import { RHFTextInput } from "./TextInput";
import { PasswordInput } from "./PasswordInput";
import { useState } from "react";
import { lang } from "../../utilities/lang";
import { PasswordReset } from "./PasswordReset";

export type LoginFormType = {
    email?: string;
    password?: string;
};

export type LoginProps = {
    link: string;
};

export const Login = (props: LoginProps) => {
    return (
        <QueryClientContext>
            <Content {...props} />
        </QueryClientContext>
    );
};

const Content = ({ link }: LoginProps) => {
    const { register, trigger, formState, handleSubmit } = useForm<LoginFormType>();
    const [error, setError] = useState<string>();
    const from = new URLSearchParams(window.location.search).get("from") ?? null;
    const loginUser = useLogin();

    const onSubmit: SubmitHandler<LoginFormType> = async (data) => {
        setError("");
        try {
            const resp = await loginUser.mutateAsync(data);
            if (resp.status === 200) {
                window.location.href = from === "cart" ? lang.get("cart.url") : link;
            }
        } catch (e) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setError(lang.get("entry.formError"));
        }
    };

    return (
        <>
            <h1>{lang.get("entry.formHeading")}</h1>
            <div>{lang.get("entry.loginText")}</div>
            {error ? <div className={"error"}>{error}</div> : null}
            <form onSubmit={handleSubmit(onSubmit)}>
                <RHFTextInput
                    label={lang.get("Email Adresse") || "Email Adresse"}
                    formState={formState}
                    register={register}
                    trigger={trigger}
                    required={true}
                    fieldName={"email"}
                />
                <PasswordInput
                    label={lang.get("Passwort") || "Passwort"}
                    formState={formState}
                    register={register}
                    fieldName={"password"}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSubmit(onSubmit);
                        }
                    }}
                />
                <button className={`button submit ${loginUser.isPending ? "loading" : ""}`} type="submit">
                    {lang.get("Anmelden")}
                </button>
                <div className="register">
                    {lang.get("entry.registerText")}
                    <a className="underline half white" href={lang.get("register.url")}>
                        {lang.get("register.title")}
                    </a>
                </div>
            </form>
            <PasswordReset />
        </>
    );
};
