import ReactDOM from "react-dom/client";
import React from "react";
import { Login } from "./components/forms/Login";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

const containers = <NodeListOf<HTMLElement>>document.querySelectorAll(".login-form");
const dashboardLink = <HTMLLinkElement>document.querySelector("#DashboardLink");
containers.forEach((container) => {
    const contentElement = <HTMLElement>container.querySelector(".content");
    const root = ReactDOM.createRoot(contentElement);
    root.render(
        React.createElement(Login, {
            link: dashboardLink.dataset.dashboardLink,
        })
    );
});
